import React from "react";

function AboutBullets({ iconClass, title, description, link }) {
  return (
    <div className="icon-box" data-aos="zoom-in" data-aos-delay="200">
      <div className="icon">
        <i className={`bx ${iconClass}`}></i>
      </div>
      <h4 className="title">
        <a href={link}>{title}</a>
      </h4>
      <p className="description">{description}</p>
    </div>
  );
}

export default AboutBullets;
