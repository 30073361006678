import React, { useState, useRef } from "react";

function WhyGMPSection() {
  const textaligncenter = { textAlign: "center" };
  return (
    <section
      className="plannewauditintro position-relative m-5 p-5"
      id="plannewauditintro"
    >
      <div className="col-md-12 content d-flex justify-content-center flex-direction-column">
        <h3 className="align-center  pb-3 text-align-center">
          Why GMPauditreports.com?
        </h3>
        <p className="mbr-text align-center pb-4 text-align-center">
          Regardless of your size, you require Active Pharmaceutical Ingredients
          (APIs) to make your medicines that even experienced buyers have a hard
          time finding. Because of strict market regulations (which are a good
          thing!), the industry is still conservative, making it inefficient and
          nontransparent for both companies and API providers. This system only
          benefits traders and results in unnecessarily high prices and medicine
          shortages. Pharmaoffer.com gives all medicine makers worldwide easy
          access to affordable high-quality raw materials.
        </p>
      </div>
    </section>
  );
}

export default WhyGMPSection;
