import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAttributeInLocalStorageData } from "../utils";
import { useAuth } from "./AuthContext";
function ProfileHeader({ showBackground }) {
  const { isLoggedIn, setIsLoggedIn } = useAuth();

  const handleNavToggle = () => {
    const navbar = document.getElementById("navbar");
    navbar.classList.toggle("navbar-mobile");

    const toggleIcon = document.querySelector(".mobile-nav-toggle");
    toggleIcon.classList.toggle("bi-list");
    toggleIcon.classList.toggle("bi-x");
  };

  const userRole = getAttributeInLocalStorageData("role");

  const handleLogout = () => {
    // Clear the authentication data from localStorage
    localStorage.removeItem("data");
    setIsLoggedIn(false);
    // Update the authentication state to false (you might need to call an appropriate function for this)
    // Example: updateAuthenticationState(false);

    // Redirect the user to the homepage or any other appropriate page
    window.location.href = "/";
  };

  const style_initialcolor = {
    color: "rgba(239, 68, 38, 1)",
    color: showBackground ? "rgb(255,255,255)" : "",
  };

  return (
    <header
      id="header"
      className="fixed-top d-flex align-items-center header-unscrolled"
      style={{
        background: showBackground ? "rgba(239, 68, 38, 1)" : "",
      }}
    >
      <div className="container d-flex align-items-center justify-content-between">
        <div className="logo">
          <h1>
            <Link to={"/"}>
              <img
                src="assets/img/Logo-1-white-text.png"
                id="header-logo-img"
                alt=""
                className="img-fluid"
              />
            </Link>
          </h1>
        </div>

        <nav id="navbar" className="navbar">
          <i
            className="bi bi-list mobile-nav-toggle"
            onClick={handleNavToggle}
          ></i>
          <ul>
            <li>
              <Link
                className="nav-link scrollto"
                to="/"
                style={style_initialcolor}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                className="nav-link scrollto"
                to="/about-us"
                style={style_initialcolor}
              >
                About Us
              </Link>
            </li>
            {isLoggedIn ? (
              <li>
                <Link
                  className="nav-link scrollto"
                  onClick={handleLogout}
                  style={style_initialcolor}
                >
                  Logout
                </Link>
              </li>
            ) : (
              <li>
                <Link
                  to="/login"
                  className="nav-link scrollto "
                  style={style_initialcolor}
                >
                  Login
                </Link>
              </li>
            )}
            <li>
              <Link
                className="nav-link scrollto "
                style={style_initialcolor}
                to="/contact-us"
              >
                Contact
              </Link>
            </li>
            {isLoggedIn ? (
              <>
                <li>
                  <Link
                    className="nav-link scrollto "
                    to="/profile"
                    style={style_initialcolor}
                  >
                    My Account
                  </Link>
                </li>
                {/*
                <li>
                  {userRole === "user" && (
                    <Link className="nav-link scrollto" to="/user/dashboard">
                      Dashboard
                    </Link>
                  )}
                  {userRole === "admin" && (
                    <Link className="nav-link scrollto" to="/admin/dashboard">
                      Dashboard
                    </Link>
                  )}
                  {userRole === "vendor" && (
                    <Link className="nav-link scrollto" to="/vendor/dashboard">
                      Dashboard
                    </Link>
                  )}
                </li> */}
              </>
            ) : (
              <></>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default ProfileHeader;
