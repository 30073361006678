import React from "react";
import AboutBullets from "../subcomponents/AboutBullets";
import aboutBulletsData from "../../data/AboutData";

function MainAboutSection() {
  return (
    <section id="about" className="about">
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch"
            data-aos="fade-right"
          >
            {/* <a className="glightbox play-btn mb-4" /> */}
          </div>

          <div
            className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5"
            data-aos="fade-left"
          >
            <h3 style={{ color: "rgb(239, 68, 38)" }}>GMP Audit Reports</h3>
            <p>
              One of a kind platform. Providing Solution as a Service for all
              your Audit related needs.
            </p>

            {aboutBulletsData.map((bullet, index) => (
              <AboutBullets
                key={index}
                iconClass={bullet.iconClass}
                title={bullet.title}
                description={bullet.description}
                link={bullet.link}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainAboutSection;
