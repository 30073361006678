const aboutBulletsData = [
  {
    iconClass: "bx-fingerprint",
    title: "For Report Buyers",
    description:
      "We provide this platform to search through our audit service provider network and connect you to your interested provider at no extra cost.",
    link: "",
  },
  {
    iconClass: "bx-gift",
    title: "For Audit Service Providers",
    description:
      "We provide this platform to update your Audit report and items for report buyers to find you out.",
    link: "",
  },
];

export default aboutBulletsData;
