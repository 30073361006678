import React, { useState, useRef } from "react";
import countries from "../../data/Countries";
import config from "../../config";
import ReCAPTCHA from "react-google-recaptcha";
import { contact } from "../../services/profile";
import { toast } from "react-toastify";
import phonepins from "../../data/PhonePins";

function MainContactSection() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    country: "",
    contact: "",
    phonepin: "",
    serviceRequired: "",
    company: "",
    subject: "",
    message: "",
  });

  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const recaptchaRef = useRef(null);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const sendContactDetails = (e) => {
    e.preventDefault();
    if (!recaptchaValue) {
      alert("Please complete the reCAPTCHA");
      return;
    }
    const temp = formData;
    temp.contact = temp.phonepin + temp.contact;
    const dataToSend = { ...temp, recaptchaString: recaptchaValue };
    contact(dataToSend)
      .then((response) => {
        console.log("Message sent successfully:", response);
        // Optionally, reset the form
        setFormData({
          name: "",
          email: "",
          country: "",
          contact: "",
          phonepin: "",
          serviceRequired: "",
          company: "",
          subject: "",
          message: "",
        });
        recaptchaRef.current.reset();
        setRecaptchaValue(null);
        toast.success("Thank you for your message. It has been sent.");
      })
      .catch((error) => {
        console.error("Error sending message:", error);
      });
  };

  return (
    <>
      <section id="contact" className="contact">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <div className="info">
                <div className="invite mb-2">
                  <i className="bi bi-info-square-fill"></i>
                  <h4>We'd Love to hear from you:</h4>
                  <p>
                    Whether you are curious about our solutions or audit reports
                    - we are happy to answer any and all questions.
                  </p>
                </div>

                <div className="address">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p>
                    Flat No. C319, Fortune Green Homes Mayura, Bachupally,
                    Hyderabad, Telangana, India - 500090
                  </p>
                </div>

                <div className="email">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p>operations@gmpauditreports.com</p>
                </div>

                <div className="phone">
                  <i className="bi bi-phone"></i>
                  <h4>Call:</h4>
                  <p>+91 9100654302</p>
                </div>

                <div className="phone">
                  <i className="bi bi-messenger"></i>
                  <h4>Find us on:</h4>
                </div>
                <div>
                  <div
                    className="linkedin"
                    style={{ display: "inline", cursor: "pointer" }}
                  >
                    <a
                      href="https://www.google.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="bi bi-linkedin mx-1 bg-primary text-white"></i>
                    </a>
                  </div>
                  <div
                    className="whatsapp"
                    style={{ display: "inline", cursor: "pointer" }}
                  >
                    <a
                      href="https://wa.me/+919100654302"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="bi bi-whatsapp mx-1 bg-success text-white"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-8 mt-5 mt-lg-0"
              data-aos="fade-left"
              data-aos-delay="200"
            >
              <form
                className="php-email-form"
                style={{
                  backgroundColor: "white",
                  borderRadius: "3vh",
                  padding: "3vh",
                }}
                onSubmit={sendContactDetails}
              >
                <div className="row border-white">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 form-group">
                    <select
                      id="country"
                      name="country"
                      className="form-control"
                      value={formData.country}
                      onChange={handleInputChange}
                      style={{ appearance: "auto" }}
                      required
                    >
                      <option value="" disabled>
                        Select a country
                      </option>
                      {countries.map((country) => (
                        <option key={country.code} value={country.code}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-2 form-group mt-3 mt-md-0">
                    <select
                      id="phonepin"
                      name="phonepin"
                      className="form-select"
                      required
                      defaultValue=""
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Country Code
                      </option>
                      {[
                        ...new Map(
                          phonepins.map((item) => [item.code, item])
                        ).values(),
                      ]
                        .sort(
                          (a, b) =>
                            parseInt(a.code.replace("+", "")) -
                            parseInt(b.code.replace("+", ""))
                        )

                        .map((phonepin) => (
                          <option key={phonepin.code} value={phonepin.code}>
                            {phonepin.code}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-md-4 form-group mt-3 mt-md-0">
                    <input
                      type="text"
                      className="form-control"
                      name="contact"
                      id="phone"
                      placeholder="Contact Number"
                      value={formData.contact}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 form-group">
                    <select
                      id="servicerequired"
                      name="serviceRequired"
                      className="form-control"
                      value={formData.serviceRequired}
                      onChange={handleInputChange}
                      style={{ appearance: "auto" }}
                      required
                    >
                      <option value="" disabled>
                        Service Required
                      </option>
                      <option value="Buy An Existing Audit Report">
                        Buy An Existing Audit Report
                      </option>
                      <option value="Audit Service Provider">
                        Audit Service Provider
                      </option>
                      <option value="Plan A New Audit">Plan A New Audit</option>
                    </select>
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="text"
                      className="form-control"
                      name="company"
                      id="company"
                      placeholder="Company"
                      value={formData.company}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="form-group mt-2">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    id="subject"
                    placeholder="Subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="5"
                    placeholder="Message"
                    value={formData.message}
                    onChange={handleInputChange}
                    required
                  ></textarea>
                </div>
                <div className="form-outline captcha-container">
                  <ReCAPTCHA
                    sitekey={config.googleRecaptchaSiteKey}
                    onChange={handleRecaptchaChange}
                    ref={recaptchaRef}
                    required
                  />
                </div>
                <div className="my-3">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-primary">
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MainContactSection;
